<template>
  <div v-if="componentLoaded">
    <Alert
      v-if="account && account.type === 'seller' && !isCompletedProfile"
      color="#7070A0"
      action="Complete profile…"
      dark
      @action-click="$router.push({name: 'account-edit', params: {'account': user.account}}, () => {})"
    >
      You still need to complete some items in your profile
      so that customers know what you sell, and whether to ship or collect.
    </Alert>
    <Alert
      v-if="account && account.type === 'seller' && !hasAcctProducts"
      color="#7070A0"
      action="Add products…"
      dark
      @action-click="$router.push({name: 'account-products-manage', params: {'account': user.account}}, () => {})"
    >
      You haven't added any products to your account yet. You'll need to do
      that before you can advertise them in the feed!
    </Alert>
    <Alert
      v-if="account && account.type === 'seller' && hasAcctProducts && !hasAcctPosts"
      color="#7070A0"
      action="Create a post…"
      dark
      @action-click="$router.push({name: 'new-post'}, () => {})"
    >
      You need to create posts in the feed to show off your products. You can
      use a video or image, and link it to up to 3 of your products so
      shoppers can tap the post to buy the products!
    </Alert>
    <template v-for="(post, index) in filteredPosts">
      <StreamPost
        :item="post"
        :key="post.id"
        :class="{'mt-4': index > 0}"
      />
    </template>
    <DiscoveryCard
      v-if="user && filteredPosts.length < 10"
      :class="{'mt-4': filteredPosts.length > 0}"
    />
  </div>
</template>

<script>
  import fb from '@/firebase'
  import { mapGetters, mapActions } from 'vuex'
  import Alert from '@/components/Alert'
  import StreamPost from '@/components/StreamPost'
  import DiscoveryCard from '@/components/DiscoveryCard'

  const COLLECTION = "posts"
  const posts = fb.db.collection(COLLECTION).orderBy('created', 'desc')

  export default {
    components: {
      Alert,
      StreamPost,
      DiscoveryCard
    },
    data: () => ({
      componentLoaded: false,
      allPosts: [],
      accountPostCount: null,
      accountProductCount: null,
      regionPosts: [],
      regionFreePosts: []
    }),
    computed: {
      ...mapGetters([
        'account',
        'getFollowAccounts',
        'getFollowTags',
        'follows'
      ]),
      filteredPosts() {
        return this.isDiscoverMode ?
          this.streamPosts :
          this.streamPosts.filter(this.showPost)
      },
      isDiscoverMode() {
        return Boolean(this.$route.name === 'discover')
      },
      streamPosts() {
        return this.allPosts.length ?
          this.allPosts : [
            ...this.regionPosts,
            ...this.regionFreePosts
          ].sort((a, b) => b.created - a.created)
      },
      isCompletedProfile() {
        return Boolean(
          this.account &&
          this.account.industry &&
          this.account.collection &&
          this.account.shipping
        )
      },
      hasAcctProducts() {
        return Boolean(
          this.account &&
          this.accountProductCount
        )
      },
      hasAcctPosts() {
        return Boolean(
          this.account &&
          this.accountPostCount
        )
      }
    },
    watch: {
      account: {
        immediate: true,
        async handler(value) {
          this.componentLoaded = false

          if (value && value.account) {
            this.accountProductCount = await this.getProductCountForAccount(value.account)
            this.accountPostCount = await this.getPostCountForAccount(value.account)
          }

          if (!this.isDiscoverMode && value && value.taxRegion) {
            try {
              this.$unbind('allPosts')
            } catch {
              // I do nuzzink!
            } finally {
              this.$bind(
                'regionFreePosts',
                posts.where("region", "==", "allregions")
              )
                .then(() => this.$bind(
                  'regionPosts',
                  posts.where("region", "==", value.taxRegion.toLowerCase())
                ))
                .then(() => this.componentLoaded = true)
            }
          } else {
            try {
              this.$unbind('regionPosts')
              this.$unbind('regionFreePosts')
            } catch {
              // I do nuzzink!
            } finally {
              this.$bind('allPosts', posts)
                .then(() => this.componentLoaded = true)
            }
          }
        }
      }
    },
    methods: {
      ...mapActions([
        'getPostCountForAccount',
        'getProductCountForAccount'
      ]),
      showPost(post) {
        const hasUser = Boolean(this.user)
        const hasFollows = Boolean(this.follows && this.follows.length)
        const isUsersAccount = hasUser && post.account === this.user.account
        const isFollowedAccount = hasUser && hasFollows && this.getFollowAccounts.includes(post.account)
        const hasFollowedTag = hasUser && hasFollows && post.tags && post.tags.length && this.getFollowTags.some(tag => post.tags.includes(tag))
        return hasUser && hasFollows ?
          (
            isUsersAccount ||
            isFollowedAccount ||
            hasFollowedTag
          ) : true
      }
    }
  }
</script>