<template>
  <v-card>
    <v-toolbar class="page-title" flat dark color="#7070a0">
      <v-toolbar-title><h1 class="title">User settings</h1></v-toolbar-title>
    </v-toolbar>
    <v-card-title>Your details</v-card-title>
    <v-card-text>
      <v-alert v-if="error" type="error" class="ma-1">{{ error.message }}</v-alert>
      <v-text-field
        label="Full name"
        :error-messages="requiredError($v.editUser.fullName, 'Full name')"
        v-model.trim="$v.editUser.fullName.$model"
        @input="$v.editUser.fullName.$touch()"
        @blur="$v.editUser.fullName.$touch()"
        size="255"
        required
      ></v-text-field>
    </v-card-text>
    <v-card-title>Login details</v-card-title>
    <v-card-text>
      <v-text-field
        label="Email"
        :error-messages="emailErrors"
        v-model.trim="$v.editUser.email.$model"
        id="email"
        @keyup="lower($v.editUser.email)"
        @input="$v.editUser.email.$touch()"
        @blur="$v.editUser.email.$touch()"
        type="email"
        size="255"
        required
      ></v-text-field>
      <v-text-field
        label="New Password"
        :error-messages="passwordErrors"
        v-model="$v.password.$model"
        @input="$v.password.$touch()"
        @blur="$v.password.$touch()"
        :append-icon="showPass ? icon.mdiEye : icon.mdiEyeOff"
        :type="showPass ? 'text' : 'password'"
        @click:append="showPass = !showPass"
        size="255"
        required
      ></v-text-field>
      <password
        v-model="$v.password.$model"
        :error-messages="passwordErrors"
        @score="score => { passwordScore = score }"
        strength-meter-only
      />
      <v-text-field
        label="Current Password"
        v-model="currentPassword"
        :error-messages="requiredError($v.currentPassword, 'Current password')"
        v-model.trim="$v.currentPassword.$model"
        @input="$v.currentPassword.$touch()"
        @blur="$v.currentPassword.$touch()"
        :append-icon="showPass2 ? icon.mdiEye : icon.mdiEyeOff"
        :type="showPass2 ? 'text' : 'password'"
        @click:append="showPass2 = !showPass2"
        size="255"
        required
      ></v-text-field>
    </v-card-text>
    <v-card-actions>
      <div class="flex-grow-1"></div>
      <v-btn
        :loading="loading"
        color="#7070a0"
        @click.stop="submit"
        dark
      >Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import Password from 'vue-password-strength-meter'
  import { validationMixin } from 'vuelidate'
  import { required, email } from 'vuelidate/lib/validators'
  import fb from '@/firebase'
  import { mdiEye, mdiEyeOff } from '@mdi/js'

  export default {
    mixins: [ validationMixin ],

    components: { Password },

    name: 'UserEdit',

    data() {
      return {
        icon: { mdiEye, mdiEyeOff },
        showPass: false,
        showPass2: false,
        originalEmail: '',
        editUser: {
          fullName: '',
          email: ''
        },
        password: '',
        currentPassword: '',
      }
    },

    activated() {
      this.$store.dispatch('clearError')
      this.$store.dispatch('setLoading', false)
      this.$store.dispatch('getDBUser', this.user.id)
        .then(userRef => {
          this.originalEmail = this.user.email
          this.editUser = {
            id: this.user.id,
            ...userRef.data()
          }
        })
        .catch(error => {
          this.logError(error.message)
        })
    },

    validations: {
      editUser: {
        fullName: { required },
        email: {
          required,
          email,
          isEmailUnique (value) {
            if (value === this.originalEmail || value === '') return true

            return fb.db.collection('users')
              .where('email', '==', value).get()
              .then(querySnapshot => {
                return !!querySnapshot.empty
              })
          }
        }
      },
      password: {
        isStrongEnough () {
          if (!this.password) return true
          return this.passwordScore > 2
        }
      },
      currentPassword: { required }
    },

    computed: {
      error () {
        return this.$store.getters.error
      },
      loading () {
        return this.$store.getters.loading
      },
      emailErrors () {
        const errors = [],
              field = this.$v.editUser.email
        if (!field.$dirty) return errors
        !field.required && errors.push('Email is required')
        !field.email && errors.push('Must be a valid email address')
        !field.isEmailUnique && errors.push('Email address already in use')
        return errors
      },
      passwordErrors () {
        const errors = [],
              field = this.$v.password
        if (!field.$dirty) return errors
        field.$model && !field.isStrongEnough && errors.push('Password must be stronger')
        return errors
      },
      routeAccount() {
        return this.$route.params.account
      },
      routeUser() {
        return this.$route.params.user_id
      }
    },

    methods: {
      lower(field) {
        field.$model = field.$model.toLowerCase()
      },
      requiredError(field, label) {
        return field && field.$dirty && !field.required ? label + ' is required' : ''
      },
      submit() {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          let authUser = fb.auth.currentUser
          this.$store.dispatch('clearError')
          this.$store.dispatch('setLoading', true)
          if (authUser) {
            this.logInfo('User authenticated')
            const updateUser = {
              uid: authUser.uid,
              account: this.user.account,
              currentEmail: this.originalEmail,
              currentPassword: this.currentPassword,
              fullName: this.editUser.fullName
            }
            if (this.editUser.email !== this.originalEmail) {
              updateUser.email = this.editUser.email
            }
            if (this.password) {
              updateUser.password = this.password
            }
            this.$store.dispatch('updateUser', updateUser)
              .then(() => {
                this.currentPassword = ''
                this.originalEmail = this.editUser.email
                this.$v.$reset()
                this.$store.dispatch('setLoading', false)
                this.$store.dispatch('triggerSnackbar', 'Saved successfully!')
              })
          }
        }
      }
    }

  }
</script>
