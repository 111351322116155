<template>
  <div v-if="componentLoaded">
    <v-btn class="back" text :to="{name: 'messages', params: {account: routeAccount}}" exact>
      <v-icon left>{{ icon.mdiArrowLeft }}</v-icon>
      Back to messages
    </v-btn>
    <h1 class="title">
      Messages to followers
    </h1>
    <v-list ref="conversation" v-if="messages">
      <template v-for="message in messages">
        <ConvoMessage
          :id="message.id"
          :key="message.id"
          :message="message"
        />
      </template>
    </v-list>
    <MessageBar action="sendMessage" @message-sent="refreshMessages" />
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import ConvoMessage from '@/components/ConvoMessage'
  import MessageBar from '@/components/MessageBar'
  import { mdiArrowLeft } from '@mdi/js'

  export default {
    components: {
      ConvoMessage,
      MessageBar
    },

    name: 'MessageConversation',

    data() {
      return {
        icon: { mdiArrowLeft },
        componentLoaded: false,
        messages: []
      }
    },

    computed: {
      routeAccount() { return this.$route.params.account }
    },

    mounted() {
      this.refreshMessages()
    },

    updated(){
      document.documentElement.scrollTop = document.documentElement.scrollHeight
    },

    methods: {
      ...mapActions([
        'setLoading',
        'getMessagesToFollowers',
      ]),

      refreshMessages() {
        return this.setLoading(true)
          .then(() => this.getMessagesToFollowers(this.routeAccount))
          .then(qs => this.messages = qs.docs.map(ds => ds.data()))
          .then(() => this.setLoading(false))
          .then(() => this.componentLoaded = true)
      }
    }
  }
</script>
