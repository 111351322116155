<template>
  <div>
    <v-card>
      <v-card-title class="title-container align-middle">
        <v-avatar size="80" tile color="indigo" class="mr-4">
          <span class="white--text display-2 font-weight-bold">#</span>
        </v-avatar>
        <h1 class="title mb-2">{{ routeTag }}</h1>
      </v-card-title>
      <v-card-actions class="justify-center">
        <template v-if="user && Array.isArray(follows)">
          <v-btn
            v-if="hasTagFollow"
            type="button"
            color="grey lighten-2"
            class="mx-1"
            depressed
            small
            @click="unfollow"
          >
            Unfollow
          </v-btn>
          <v-btn
            v-else
            type="button"
            color="#7070a0"
            class="mx-1"
            depressed
            small
            dark
            @click="follow"
          >
            <v-icon left dark>{{ icon.mdiTagPlus }}</v-icon>
            Follow
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
    <template
      v-for="(post, index) in streamPosts"
    >
      <StreamPost
        :item="post"
        :index="index"
        :key="post.id"
        class="mt-4"
      />
    </template>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import StreamPost from '@/components/StreamPost'
  import { mdiTagPlus } from '@mdi/js'

  export default {

    name: 'TagStream',

    components: {
      StreamPost
    },

    activated() {
      this.$store.dispatch('getPostsForTag', this.routeTag)
        .then(querySnapshot => {
          querySnapshot.forEach(postSnapshot => {
            const post = {
              id: postSnapshot.id,
              ...postSnapshot.data()
            }

            this.streamPosts.push(post)
          })
        })
    },

    deactivated() {
      this.streamPosts = []
    },

    data() {
      return {
        icon: { mdiTagPlus },
        streamPosts: []
      }
    },

    computed: {
      routeTag() { return this.$route.params.tag },
      tagFollow() { return {follower: this.user.account, followType: 'tag', followID: this.routeTag} },
      hasTagFollow() { return Boolean(this.getFollow(this.tagFollow)) },
      ...mapGetters([
        'getFollow',
        'follows'
      ])
    },

    methods: {
      follow() {
        this.$store.dispatch('follow', this.tagFollow)
      },
      unfollow() {
        this.$store.dispatch('unfollow', this.tagFollow)
      },
    }
  }
</script>

<style lang="scss">
</style>
