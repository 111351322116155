<template>
  <div v-if="componentLoaded">
    <AccountHeader :account="profileAccount" :tags="productTags"></AccountHeader>
    <template
      v-for="(product, index) in products"
    >
      <StreamProduct
        v-if="!filter || filter.split(',').every(f => product.tags.includes(f))"
        :product="product"
        :key="product.id"
        :class="{'mt-4': index > 0}"
        @deleted="updateProducts"
      />
    </template>
    <PromptCard
      v-if="showPromptCard"
      label="You have not added any products yet."
    >
      <v-btn
        type="button"
        :to="{ name: 'add-product', params: {account: user.account} }"
        exact
        color="#7070a0"
        dark
        depressed
      >
        <v-icon left>{{ icon.mdiPlusCircle }}</v-icon>
        Add a product
      </v-btn>
    </PromptCard>
  </div>
</template>

<script>
  import AccountHeader from '@/components/AccountHeader'
  import StreamProduct from '@/components/StreamProduct'
  import PromptCard from '@/components/PromptCard'
  import { mdiPlusCircle } from '@mdi/js'

  export default {

    name: 'AccountProducts',

    components: {
      AccountHeader,
      StreamProduct,
      PromptCard
    },

    data() {
      return {
        componentLoaded: false,
        icon: { mdiPlusCircle },
        profileAccount: null,
        productTags: null,
        products: []
      }
    },

    computed: {
      filter() { return this.$route.query.filter },
      routeAccount() { return this.$route.params.account },
      showPromptCard() { return Boolean(
        this.user &&
        this.user.account === this.routeAccount &&
        this.profileAccount &&
        this.profileAccount.type === 'seller' &&
        !this.products.length
      ) }
    },

    watch: {
      routeAccount: {
        immediate: true,
        handler(value) {
          Promise.all([
            this.$store.dispatch('getAccount', value)
              .then(account => this.profileAccount = account),
            this.$store.dispatch('getProductTagsForAccount', value)
              .then(tags => this.productTags = tags),
            this.updateProducts(value)
          ])
            .then(() => this.componentLoaded = true)
            .catch(error => {
              this.logError('Problem loading data.', { error: error.message })
            })
        }
      }
    },

    methods: {
      updateProducts(account) {
        return this.$store.dispatch('getAccountProducts', account)
          .then(products => this.products = products)
      }
    }
  }
</script>
