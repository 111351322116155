<template>
  <StreamProduct
    v-if="product"
    :product="product"
  />
</template>

<script>
  import StreamProduct from '@/components/StreamProduct'

  export default {
    components: {
      StreamProduct,
    },

    name: 'SingleProduct',

    data() {
      return {
        product: null
      }
    },

    computed: {
      routeProduct() {
        return this.$route.params.product_id
      }
    },

    activated() {
      this.$store.dispatch('getProduct', this.routeProduct)
        .then(product => this.product = product)
    },

    deactivated() {
      this.product = null
    }
  }
</script>

<style lang="scss">
</style>
