<template>
  <div v-if="componentLoaded">
    <MessageHeader :currentAccount="routeAccount" :displayAccount="routeRecipient" />
    <v-list ref="conversation" v-if="currentConversation">
      <template v-for="message in currentConversation.messages">
        <ConvoMessage
          :id="message.ulid"
          :key="message.ulid"
          :message="message"
        />
      </template>
    </v-list>
    <MessageBar action="sendConversationMessage" />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import ConvoMessage from '@/components/ConvoMessage'
  import MessageHeader from '@/components/MessageHeader'
  import MessageBar from '@/components/MessageBar'

  export default {
    components: {
      ConvoMessage,
      MessageHeader,
      MessageBar
    },

    name: 'MessageConversation',

    data() {
      return {
        componentLoaded: false,
        message: ''
      }
    },

    computed: {
      ...mapGetters([
        'currentConversation',
        'conversations'
      ]),
      routeAccount() { return this.$route.params.account },
      routeRecipient() { return this.$route.params.recipient }
    },

    mounted() {
      this.initConversation({
        participants: [
          this.routeAccount,
          this.routeRecipient
        ],
        reader: this.routeAccount
      })
        .then(() => this.componentLoaded = true)
    },

    updated(){
      document.documentElement.scrollTop = document.documentElement.scrollHeight
    },

    methods: {
      ...mapActions([
        'initConversation'
      ])
    }
  }
</script>
