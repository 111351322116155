<template>
  <div>
    <v-card v-if="searchResults && searchResults.length">
      <v-list>
        <SearchResultItem
          v-for="item in searchResults"
          :key="item._id"
          :item="item"
        ></SearchResultItem>
      </v-list>
    </v-card>
    <div class="text-center py-8" v-else-if="searchLoading">
      <v-progress-circular
        indeterminate
        color="#7070a0"
        class="ma-4"
      ></v-progress-circular>
      <p>Please wait&hellip;</p>
    </div>
    <div class="text-center py-8" v-else>
      <p>No results found for "{{ searchQuery }}".</p>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import SearchResultItem from '@/components/SearchResultItem'

  export default {

    name: 'SearchResults',

    components: {
      SearchResultItem
    },

    computed: {
      ...mapGetters([
        'searchLoading',
        'searchQuery',
        'searchResults'
      ])
    }
  }
</script>
