<template>
  <v-card v-if="componentLoaded">
    <v-card-title>
      <h1 class="headline">Select product</h1>
    </v-card-title>
    <v-list>
      <v-list-item-group
        v-model="selected"
      >
        <Product
          v-for="(product, index) in displayProducts"
          :product="product"
          :key="index"
          @click="selectProduct(product.id)"
        />
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import Product from '@/components/Product'

  export default {
    components: {
      Product,
    },

    name: 'ProductChoose',

    data() {
      return {
        componentLoaded: false,
        postProducts: [],
        selected: null
      }
    },

    activated() {
      this.getPost(this.routePost)
        .then(docRef => {
          this.postProducts = docRef.get('products') || []
          return this.bindAccountProductsRef(this.routeAccount)
        })
        .then(() => this.componentLoaded = true)
    },

    deactivated() {
      this.componentLoaded = false
      this.postProducts = []
      this.selected = null
    },

    computed: {
      ...mapGetters([
        'accountProducts'
      ]),
      routeAccount() {
        return this.$route.params.account
      },
      routePost() {
        return this.$route.params.post_id
      },
      displayProducts() {
        return this.accountProducts.filter(
          p => !this.postProducts.includes(p.id)
        )
      }
    },

    watch: {
      selected(product) {
        if (product) {
          this.$store.dispatch('setLoading', true)
          this.addPostProduct({
            postID: this.routePost,
            productID: product
          })
            .then(() => {
              this.$store.dispatch('setLoading', false)
              this.$router.push({
                name: 'manage-products',
                params: {
                  account: this.user.account,
                  postId: this.routePost
                }
              })
            })
        }
      }
    },

    methods: {
      ...mapActions([
        'bindAccountProductsRef',
        'addPostProduct',
        'getPost'
      ])
    }
  }
</script>
