<template>
  <v-card v-if="componentLoaded" class="messages-card">
    <v-toolbar class="page-title" flat dark color="#7070a0">
      <v-toolbar-title><h1 class="title">Messages</h1></v-toolbar-title>
      <v-toolbar-title v-if="user" class="text-right">{{ user.account }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text
      v-if="!hasMessages"
      class="messages-card--text text-center subtitle-1 my-4"
    >
      You currently have no messages.
    </v-card-text>
    <v-list v-else>
      <template v-for="(message, index) in displayMessages">
        <v-list-item
          :to="message.isNotice ?
            {name: 'message-detail', params: {account: user.account, message_id: message.id}} :
            {name: 'messages-convo', params: {account: user.account, recipient: getParticipantKey(message, 'account')}}"
          :key="index"
          :class="{'is-notice': message.isNotice, 'is-read': message.read, 'display-message': true}"
        >
          <v-list-item-content class="display-sender">
            <v-list-item-title class="body-2">
              {{ getParticipantKey(message, 'account') }}
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-content class="display-text">
            <v-list-item-subtitle class="body-2">
              <strong v-if="message.isNotice">Notice:</strong>
              {{ message.text }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-content class="display-date">
            <v-list-item-subtitle class="caption">{{ formatDate(message.sent) }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider
          v-if="index < displayMessages.length - 1"
          :key="`divider${index}`"
        />
      </template>
    </v-list>
    <v-card-actions class="justify-end">
      <v-menu offset-y v-if="isSeller">
        <template v-slot:activator="{ on, value }">
          <v-btn
            color="#7070a0"
            dark
            depressed
            v-on="on"
          >
            <v-icon left>{{ icon.mdiMessagePlus }}</v-icon>
            New message
            <v-icon right v-if="value">{{ icon.mdiMenuUp }}</v-icon>
            <v-icon right v-else>{{ icon.mdiMenuDown }}</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in [
              { text: 'Direct', route: {name: 'messages-new', params: {account: user.account}} },
              { text: 'To followers', route: {name: 'messages-followers', params: {account: user.account}} }
            ]"
            :key="index"
            :to="item.route"
          >
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        v-else
        color="#7070a0"
        dark
        depressed
        :to="{name: 'messages-new', params: {account: user.account}}"
      >
        <v-icon left>{{ icon.mdiMessagePlus }}</v-icon>
        New message
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { formatDistanceStrict } from 'date-fns'
  import { getImageURL } from '@/image-cdn'
  import { mdiMessagePlus, mdiMenuUp, mdiMenuDown } from '@mdi/js'

  export default {

    name: 'Messages',

    data() {
      return {
        icon: { mdiMessagePlus, mdiMenuUp, mdiMenuDown },
        accountLookup: {},
        componentLoaded: false
      }
    },

    computed: {
      routeAccount() {
        return this.$route.params.account
      },
      hasMessages() {
        return Boolean(this.displayMessages && this.displayMessages.length)
      },
      displayMessages() {
        return [
          ...this.messages.map(m => ({...m, id: m.id, isNotice: true})),
          ...this.conversations.reduce((acc, convo) => {
            const message = {...convo.messages[convo.messages.length - 1]}
            const state = this.routeAccount in convo.participantState ?
              convo.participantState[this.routeAccount] : undefined
            message.read = state && 'read' in state ? state.read : false
            acc.push(message)
            return acc
          }, [])
        ].sort((a, b) => b.sent - a.sent)
      },
      ...mapGetters([
        'isSeller',
        'conversations',
        'messages'
      ])
    },

    watch: {
      conversations() {
        this.refreshAccountLookup()
      },
      messages() {
        this.refreshAccountLookup()
      }
    },

    mounted() {
      this.refreshAccountLookup(() => this.componentLoaded = true)
    },

    methods: {
      formatDate(date) {
        return formatDistanceStrict(new Date(date), new Date().getTime())
      },
      refreshAccountLookup(callback) {
        if (this.hasMessages) {
          let allAccounts = new Set(
            this.displayMessages
              .flatMap(m => [m.sender, m.recipient])
              .filter(Boolean) // strip falsey
          )
          allAccounts.add(this.routeAccount)

          this.$store.dispatch('getAccountsIn', allAccounts)
            .then(qs => {
              qs.forEach(ds => {
                const data = ds.data()
                this.accountLookup[ds.id] = {
                  ...data,
                  mediaSrc: data.media ? getImageURL(data.media, 80, 80, data.updated || data.created) : null
                }
              })
            })
            .then(callback)
        } else {
          callback()
        }
      },
      getParticipantKey(message, key) {
        const participant = message.sender === this.user.account ?
          message.recipient :
          message.sender
        const participantAccount = participant in this.accountLookup ?
          this.accountLookup[participant] :
          null
        return participantAccount && key in participantAccount ?
          participantAccount[key] :
          null
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~vuetify/src/styles/settings/_colors.scss';

  .display-message {
    &.is-read {
      background-color: map-get($grey, "lighten-4");

      .display-sender .body-2,
      .display-text .body-2 {
        font-weight: normal;

        strong {
          font-weight: normal;
        }
      }
    }
    &.is-notice:not(.is-read) {
      background-color: #f9dfd2;
    }

    .display-sender,
    .display-text {
      & .body-2 {
        font-weight: bold;
      }

      flex: 1;
    }

    .display-date {
      flex: initial;
      width: 64px;
      text-align: right;
    }
  }
</style>
