<template>
  <div v-if="componentLoaded">
    <AccountHeader :account="profileAccount" :tags="postTags" :loader="loadPosts"></AccountHeader>
    <template
      v-for="(post, index) in streamPosts"
    >
      <StreamPost
        v-if="!filter || filter.split(',').every(f => post.tags.includes(f))"
        :item="post"
        :loader="loadPosts"
        :index="index"
        :key="post.id"
        :class="{'mt-4': index > 0}"
      />
    </template>
    <PromptCard
      v-if="showPromptCard"
      label="You have not created any posts yet.
        Posts allow you to showcase your products in your followers' timelines."
    >
      <v-btn
        type="button"
        :to="{name: 'new-post'}"
        exact
        color="#7070a0"
        dark
        depressed
      >
        <v-icon left>{{ icon.mdiPlusCircle }}</v-icon>
        Create a post
      </v-btn>
    </PromptCard>
  </div>
</template>

<script>
  import AccountHeader from '@/components/AccountHeader'
  import StreamPost from '@/components/StreamPost'
  import PromptCard from '@/components/PromptCard'
  import { mdiPlusCircle } from '@mdi/js'

  export default {

    name: 'Profile',

    components: {
      AccountHeader,
      StreamPost,
      PromptCard
    },

    activated() {
      this.$store.dispatch('getAccount', this.routeAccount)
        .then(account => {
          this.profileAccount = account

          return this.loadPosts()
        })
        .then(() => this.componentLoaded = true)
        .catch(error =>
          this.logError('Problem loading data.', { error: error.message })
        )
    },

    deactivated() {
      this.profileAccount = null
      this.postTags = null
      this.streamPosts = []
    },

    data() {
      return {
        componentLoaded: false,
        icon: { mdiPlusCircle },
        profileAccount: null,
        postTags: null,
        streamPosts: []
      }
    },

    computed: {
      filter() { return this.$route.query.filter },
      routeAccount() { return this.$route.params.account },
      isOwnProfile() { return this.user.account === this.routeAccount },
      showPromptCard() {
        return Boolean(
          this.profileAccount &&
          this.profileAccount.type === 'seller' &&
          this.user &&
          this.isOwnProfile &&
          !this.streamPosts.length
        )
      }
    },

    methods: {
      loadPosts() {
        return Promise.all([
          this.$store.dispatch('getPostTagsForAccount', this.routeAccount)
            .then(tags => this.postTags = tags),
          this.$store.dispatch('getPostsForAccount', this.routeAccount)
            .then(querySnapshot => {
              querySnapshot.forEach(postSnapshot =>
                postSnapshot &&
                postSnapshot.id !== this.profileAccount.pinnedPost &&
                this.streamPosts.push({
                  id: postSnapshot.id,
                  ...postSnapshot.data()
                })
              )
            })
        ])
      }
    }
  }
</script>
