<template>
  <form method="post" @submit.prevent="submit">
    <v-card>
      <v-card-title>
        <h1 class="headline">Edit product</h1>
      </v-card-title>
      <div v-if="componentLoaded">
        <MediaUpload
          v-model="productMedia"
          ref="mediaUpload"
          :icon="icon.mdiFileImage"
          label="Upload a product image"
          @preview="productMediaDirty = true"
          type="image"
          :previewWidth="160"
          :previewHeight="100"
          accept="image/*"
        ></MediaUpload>
        <v-card-text>
          <v-text-field
            label="Product title"
            v-model.trim="$v.product.title.$model"
            @input="$v.product.title.$touch()"
            @blur="$v.product.title.$touch()"
            :error-messages="requiredError($v.product.title, 'Title')"
          ></v-text-field>
          <v-textarea
            label="Product description"
            class="mt-1"
            v-model.trim="product.description"
            filled
          ></v-textarea>
          <div class="d-flex align-start mt-1">
            <v-icon color="#7070a0" left>{{ icon.mdiHelpCircle }}</v-icon>
            <p class="caption grey--text text--darken-2">Tags are a way for you
            to classify your products with words or phrases that customers might
            search for and follow or browse by. For example, they might describe
            the product, or a philosophy you work by e.g. "chocolate" or
            "eco-friendly".</p>
          </div>
          <v-combobox
            label="Product tags"
            v-model="$v.product.tags.$model"
            chips
            small-chips
            clearable
            multiple
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                close
                small
                @click="select"
                @click:close="remove(item, $v.product.tags.$model)"
              >
                {{ item }}
              </v-chip>
            </template>
          </v-combobox>
          <div class="d-flex align-start mt-1">
            <v-icon color="#7070a0" left>{{ icon.mdiHelpCircle }}</v-icon>
            <p class="caption grey--text text--darken-2">Similar to other ecommerce platforms, every
            product or service in Nuokka must have a code to allow easy tracking. This is usually
            known as an SKU (or Stock Keeping Unit code), can be any mix of
            text characters, but <strong>must be unique within your catalogue</strong>.
            Examples: MyProduct1, CakeBox1, 1, A, AAA-123 etc.</p>
          </div>
          <v-text-field
            label="Product code / SKU"
            v-model.trim="$v.product.code.$model"
            @input="$v.product.code.$touch()"
            @blur="$v.product.code.$touch()"
            :error-messages="codeErrors()"
          ></v-text-field>
          <div class="d-flex align-start mt-1">
            <v-icon color="#7070a0" left>{{ icon.mdiHelpCircle }}</v-icon>
            <p class="caption grey--text text--darken-2">Unit Size represents
            how many items should be considered a single unit, in relation to the
            price per unit entered below. In most cases, this is likely to be
            "Each" to represent a single item or service per price
            paid. Alternatively it might be "per box of 20" or "per 500g".</p>
          </div>
          <v-text-field
            label="Unit Size"
            v-model.trim="$v.product.unitSize.$model"
            @input="$v.product.unitSize.$touch()"
            @blur="$v.product.unitSize.$touch()"
            :error-messages="requiredError($v.product.unitSize, 'Unit size')"
          ></v-text-field>
          <div class="d-flex align-start mt-1">
            <v-icon color="#7070a0" left>{{ icon.mdiHelpCircle }}</v-icon>
            <p class="caption grey--text text--darken-2">Current Stock tracks
            how many specific units of stock you are able to
            sell. This will deplete with each quantity sold per order. It
            directly represents how many units of this specific SKU you have to
            sell at Unit Price per Unit Size. In some cases it is likely you may
            have an unlimited number, in which case we advise entering a large
            number, such as 1000, and regularly updating it as it gets low.</p>
          </div>
          <v-text-field
            label="Current stock"
            class="text-right"
            v-model.trim="$v.product.stock.$model"
            @input="$v.product.stock.$touch()"
            @blur="$v.product.stock.$touch()"
            :error-messages="stockErrors()"
          ></v-text-field>
          <h2 class="headline mb-2">Shipping</h2>
          <v-select
            v-if="account.shipping !== 'unavailable'"
            label="Shipping"
            :items="items.shipping"
            v-model.trim="$v.product.shipping.$model"
            @input="$v.product.shipping.$touch()"
            @blur="$v.product.shipping.$touch()"
            :error-messages="requiredError($v.product.shipping, 'Shipping option')"
          ></v-select>
          <v-select
            v-if="account.collection === 'available-some'"
            label="Collection"
            :items="items.collection"
            v-model.trim="$v.product.collection.$model"
            @input="$v.product.collection.$touch()"
            @blur="$v.product.collection.$touch()"
            :error-messages="requiredError($v.product.collection, 'Collection option')"
          ></v-select>
          <PriceForm
            v-model="product.prices"
            title="Price"
            :include-shipping="includeProductShipping"
          />
          <h2 class="headline mb-2">Tax</h2>
          <div class="d-flex align-start">
            <v-icon color="#7070a0" left>{{ icon.mdiHelpCircle }}</v-icon>
            <p class="caption grey--text text--darken-2">A tax category is
            required for specific products when calculating sales tax in
            various territories. If you cannot find a relevant category for your
            product, you must select "fully taxable product".</p>
          </div>
          <TaxCategorySelect
            label="Tax category"
            v-model.trim="$v.product.taxCode.$model"
            @input="$v.product.taxCode.$touch()"
            @blur="$v.product.taxCode.$touch()"
            :error-messages="requiredError($v.product.taxCode, 'Tax category')"
          ></TaxCategorySelect>
          <h2 class="headline mb-2">Variant Options</h2>
          <div class="d-flex align-start">
            <v-icon color="#7070a0" left>{{ icon.mdiHelpCircle }}</v-icon>
            <p class="caption grey--text text--darken-2">Variants of products
            are created when you add custom options, such as size or colour. Each
            combination of options for a product is a variant of that product. Each
            variant may have its own SKU, prices, and stock. If you add options
            here, you will automatically be taken to the variant editing screens
            once you have saved this product.</p>
          </div>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn
              icon
              small
              @click.stop="addCustom"
              type="button"
              :disabled="product.customOptions.length > 2"
            >
              <v-icon>{{ icon.mdiPlus }}</v-icon>
            </v-btn>
            <v-chip
              class="ma-2"
              color="green"
              text-color="white"
            >{{ product.customOptions.length }} option(s)</v-chip>
            <v-btn
              icon
              small
              @click.stop="removeCustom"
              type="button"
              :disabled="product.customOptions.length < 1"
            >
              <v-icon>{{ icon.mdiMinus }}</v-icon>
            </v-btn>
            <div class="flex-grow-1"></div>
          </v-card-actions>
          <div
            v-for="(v, index) in $v.product.customOptions.$each.$iter"
            :key="'custom' + index"
          >
            <v-text-field
              :label="'Option ' + (Number(index) + 1) + ' Description'"
              hint="(e.g. color, size, material, style)"
              persistent-hint
              v-model.trim="v.description.$model"
              @input="v.description.$touch()"
              @blur="v.description.$touch()"
              :error-messages="requiredError(v.description, 'A variant description')"
            ></v-text-field>
            <v-combobox
              :label="'Option ' + (Number(index) + 1) + ' Values'"
              hint="These will be converted into selectable options"
              persistent-hint
              v-model="v.options.$model"
              @input="v.options.$touch()"
              @blur="v.options.$touch()"
              :error-messages="requiredError(v.options, 'A variant value')"
              chips
              small-chips
              clearable
              multiple
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  small
                  @click="select"
                  @click:close="remove(item, v.options.$model)"
                >
                  {{ item }}
                </v-chip>
              </template>
            </v-combobox>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :to="backRoute"
            class="back"
            text exact>
            <v-icon left>{{ icon.mdiArrowLeft }}</v-icon>
            Back to products
          </v-btn>
          <div class="flex-grow-1"></div>
          <v-btn
            :loading="loading"
            dark
            depressed
            color="#7070a0"
            type="submit"
          >Save</v-btn>
        </v-card-actions>
      </div>
      <PleaseWait v-else/>
    </v-card>
  </form>
</template>

<script>
  import { mapGetters } from 'vuex'
  import fb from '@/firebase'
  import { validationMixin } from 'vuelidate'
  import { required, decimal } from 'vuelidate/lib/validators'
  import Product from '@/aggroot/product'
  import MediaUpload from '@/components/MediaUpload'
  import TaxCategorySelect from '@/components/TaxCategorySelect'
  import PriceForm from '@/components/PriceForm'
  import PleaseWait from '@/components/app/PleaseWait'
  import {
    mdiFileImage,
    mdiPlus,
    mdiMinus,
    mdiHelpCircle,
    mdiArrowLeft
  } from '@mdi/js'

  const MAX_CUSTOM = 3

  export default {
    mixins: [validationMixin],
    components: {
      MediaUpload,
      TaxCategorySelect,
      PriceForm,
      PleaseWait
    },
    activated() {
      if (this.routeProduct) {
        this.$store.dispatch('getProduct', this.routeProduct)
          .then(product => {
            this.product = product.toObj()

            this.oldCode = this.product.code
            this.oldTags = [...this.product.tags]

            if (this.product.hasMedia) {
              this.$store.dispatch('getAccountProductImage', {
                account: this.product.account,
                productId: this.product.id
              })
              .then(url => fb.storage.ref(url).getDownloadURL())
              .then(url => this.productMedia = url)
              .catch(error => {
                this.logError(error.message)
              })
            }

            this.componentLoaded = true
            this.$v.$touch()
          }).catch(error => {
            this.logError(error.message)
          })
      } else {
        this.componentLoaded = true
      }
    },
    data: () => ({
      componentLoaded: false,
      icon: {
        mdiArrowLeft,
        mdiFileImage,
        mdiHelpCircle,
        mdiMinus,
        mdiPlus
      },
      items: {
        shipping: [{
          text: 'Unavailable',
          value: 'unavailable'
        }, {
          text: 'Available',
          value: 'available'
        }, {
          text: 'By arrangement',
          value: 'arrangement'
        }],
        collection: [{
          text: 'Unavailable',
          value: false
        }, {
          text: 'Available',
          value: true
        }],
      },
      infoCode: false,
      infoUnitSize: false,
      infoStock: false,
      product: {
        title: "",
        description: "",
        code: "",
        unitSize: "Each",
        stock: 1,
        shipping: "",
        collection: "",
        prices: [{
          currency: null,
          unitPrice: null,
          shipping: null
        }],
        taxCode: "fully-taxable-product",
        tags: [],
        customOptions: []
      },
      oldCode: null,
      oldTags: false,
      productMediaDirty: false,
      productMedia: ""
    }),
    validations() {
      const validations = {
        product: {
          title: {
            required
          },
          code: {
            required,
            isSKUUnique: function(value) {
              return !this.$v.product.code.$dirty ||
                (this.product.oldCode && value != this.product.oldCode) ||
                fb.db.collection('products')
                  .where('account', '==', this.user.account)
                  .where('code', '==', value)
                  .get()
                  .then(documentSnapshot => !documentSnapshot.exists)
                  .catch(error => this.logError('isSKUUnique error', { error }))
            }
          },
          unitSize: {
            required
          },
          stock: {
            required,
            decimal
          },
          tags: {},
          taxCode: {
            required
          },
          customOptions: {
            $each: {
              description: {
                required
              },
              options: {
                required
              }
            }
          }
        }
      }

      if (this.account.shipping !== 'unavailable') {
        validations.product.shipping = { required }
      }

      if (this.account.collection === 'available-some') {
        validations.product.collection = { required }
      }

      return validations
    },
    computed: {
      ...mapGetters([
        "account"
      ]),
      routeProduct() {
        return this.$route.params.product_id
      },
      routePost() {
        return this.$route.params.post_id || this.$route.query.post
      },
      accountProducts() {
        return this.$store.getters.accountProducts
      },
      error() {
        return this.$store.getters.error
      },
      loading() {
        return this.$store.getters.loading
      },
      backRoute() {
        return this.routePost ? {
          name: 'manage-products',
          params: {
            account: this.user && this.user.account,
            post_id: this.routePost
          }
        } : {
          name: 'account-products-manage',
          params: {
            account: this.user && this.user.account
          }
        }
      },
      includeProductShipping() {
        return Boolean(
          this.account.shipping === 'product' &&
          this.product.shipping === 'available'
        )
      }
    },
    watch: {
      account(value) {
        if (value && value.currency) {
          if (this.product.prices[0].currency === null) {
            this.product.prices[0].currency = value.currency
          }
        }
      }
    },
    methods: {
      remove (tag, tags) {
        tags.splice(tags.indexOf(tag), 1)
        tags = [...tags]
      },
      addCustom() {
        const co = this.product.customOptions
        if(co.length < MAX_CUSTOM) co.push({
          description: "",
          options: []
        })
      },
      removeCustom() {
        const co = this.product.customOptions
        if(co.length) co.pop()
      },
      requiredError(field, label) {
        return field.$dirty && !field.required ? label + ' is required' : ''
      },
      codeErrors() {
        const errors = []
        const field = this.$v.product.code
        if (!field.$dirty) return errors
        !field.required && errors.push("Code is required")
        !field.isSKUUnique && errors.push("Code must be unique")
        return errors
      },
      stockErrors() {
        const errors = []
        const field = this.$v.product.stock
        if (!field.$dirty) return errors
        !field.required && errors.push("Stock is required")
        !field.decimal && errors.push("Stock must be a decimal value")
        return errors
      },
      submit() {
        this.$v.$touch()
        if (this.$v.$invalid) {
          document.documentElement.scrollTop = 0
        } else {
          this.$store.dispatch('setLoading', true)
          let product_to_store = Product.fromObj({
            ...this.product,
            userId: this.user.id,
            account: this.user.account,
            hasMedia: !!this.productMedia
          })

          this.$store.dispatch(
            product_to_store.id ? 'updateProduct' : 'createProduct',
            product_to_store
          )
            .then(docRef => {
              this.logInfo("Product stored.", product_to_store)
              let updates = []

              if (this.routePost) {
                updates.push(
                  this.$store.dispatch('addPostProduct', {
                    postID: this.routePost,
                    productID: docRef.id
                  })
                )
              }

              if (product_to_store.tags && product_to_store.tags.length) {
                updates.push(
                  this.$store.dispatch('updateTags', {
                    tags: product_to_store.tags,
                    collection: 'products',
                    oldTags: this.oldTags
                  })
                )

                this.oldTags = [...product_to_store.tags]
              }

              if (this.productMediaDirty && this.productMedia) {
                let mediaPath = `products/${ this.user.account }/`
                mediaPath += this.routeProduct ? this.routeProduct : docRef.id
                updates.push(
                  this.$refs.mediaUpload.upload(mediaPath)
                )
              }

              return Promise.all(updates)
            })
            .then(() => {
              this.$v.$reset()
              this.$store.dispatch('setLoading', false)
              this.$router.push(this.backRoute)
            })
            .catch((error) => {
              this.$store.dispatch('setLoading', false)
              this.logError("Error storing product", {error: error.message})
            })
        }
      }
    }
  }
</script>
