<template>
  <v-card v-if="componentLoaded" class="messages-card">
    <v-toolbar class="page-title" flat dark color="#7070a0">
      <v-toolbar-title><h1 class="title">New Message</h1></v-toolbar-title>
    </v-toolbar>
    <v-card-actions>
      <v-btn class="back" text :to="{name: 'messages', params: {account: routeAccount}}" exact>
        <v-icon left>{{ icon.mdiArrowLeft }}</v-icon>
        Back to messages
      </v-btn>
    </v-card-actions>
    <v-card-text
      v-if="!contacts || !contacts.length"
      class="messages-card--text text-center subtitle-1 my-4"
    >
      You are not following anyone to message.
    </v-card-text>
    <v-list v-else>
      <template v-for="(contact, index) in contacts">
        <v-list-item
          :to="{name: 'messages-convo', params: {account: user.account, recipient: getKeyFromLookup(contact, 'account')}, query: { share }}"
          :key="index"
        >
          <v-list-item-avatar :color="getKeyFromLookup(contact, 'mediaSrc') ? null : '#7070a0'" size="32">
            <v-img v-if="getKeyFromLookup(contact, 'mediaSrc')" :src="getKeyFromLookup(contact, 'mediaSrc')" :alt="getKeyFromLookup(contact, 'account')"></v-img>
            <span v-else class="white--text headline">{{ getKeyFromLookup(contact, 'account')[0].toUpperCase() }}</span>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="body-2 font-weight-bold">
              {{ getKeyFromLookup(contact, 'legalName') }}
              <span class="text--secondary font-weight-regular">@{{ getKeyFromLookup(contact, 'account') }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider
          v-if="index + 1 < contacts.length"
          :key="'divider' + index"
        ></v-divider>
      </template>
    </v-list>
  </v-card>
</template>

<script>
  import { getImageURL } from '@/image-cdn'
  import { mdiArrowLeft } from '@mdi/js'

  export default {

    name: 'MessageRecipient',

    data() {
      return {
        icon: { mdiArrowLeft },
        accountLookup: {},
        componentLoaded: false,
        contacts: []
      }
    },

    computed: {
      routeAccount() { return this.$route.params.account },
      share() { return this.$route.query.share }
    },

    mounted() {
      this.$store.dispatch('getFollowed', this.user.account)
        .then(qs => !qs.empty ? qs.docs
          .filter(ds => ds.get('followType') == 'account')
          .forEach(ds => this.contacts.push(ds.get('followID'))) :
          null
        )
        .then(() => this.contacts.length ? this.$store.dispatch('getAccountsIn', this.contacts) : null)
        .then(qs => {
          if (qs) {
            qs.forEach(ds => {
              const data = ds.data()
              this.accountLookup[ds.id] = {
                ...data,
                mediaSrc: data.media ? getImageURL(data.media, 80, 80, data.updated || data.created) : null
              }
            })
          }
          this.componentLoaded = true
        })
    },

    methods: {
      getKeyFromLookup(contact, key) {
        const account = contact in this.accountLookup ? this.accountLookup[contact] : null

        return account && key in account ? account[key] : null
      }
    }
  }
</script>

<style lang="scss">
</style>
