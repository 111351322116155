<template>
  <v-card v-if="componentLoaded" class="messages-card">
    <v-toolbar class="page-title" flat dark color="#7070a0">
      <v-toolbar-title><h1 class="title">Message to followers</h1></v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <MessageHeader v-if="message" :currentAccount="routeAccount" :displayAccount="message.sender" />
      <v-list class="notice">
        <ConvoMessage
          :id="message.ulid"
          :message="message"
          fullwidth
        />
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapActions } from 'vuex'
  import ConvoMessage from '@/components/ConvoMessage'
  import MessageHeader from '@/components/MessageHeader'

  export default {
    components: {
      ConvoMessage,
      MessageHeader
    },

    name: 'MessageNotice',

    data() {
      return {
        componentLoaded: false,
        message: {}
      }
    },

    computed: {
      routeAccount() {
        return this.$route.params.account
      },
      routeID() {
        return this.$route.params.message_id
      }
    },

    mounted() {
      this.getNoticeMessage(this.routeID)
        .then(message => this.message = {...message.data(), id: message.id})
        .then(() => this.componentLoaded = true)
        .then(() => this.markMessageAsRead(this.message.id))
    },

    methods: {
      ...mapActions([
        'getNoticeMessage',
        'markMessageAsRead'
      ])
    }
  }
</script>

<style lang="scss">
</style>
