<template>
  <v-card>
    <v-toolbar class="page-title" flat dark color="#7070a0">
      <v-toolbar-title><h1 class="title">Wishlist</h1></v-toolbar-title>
    </v-toolbar>

    <v-alert v-if="error" type="error" class="ma-1">{{ error.message }}</v-alert>

    <v-card-text
      v-if="!list || !list.items.length"
      class="text-center subtitle-1 my-4"
    >
      Your wishlist is currently empty.
    </v-card-text>

    <v-list v-else>
      <v-list-item-group v-model="selected" multiple>
        <template v-for="(item, index) in list.items">
          <v-list-item
            :key="index"
            :value="item"
          >
            <template v-slot:default="{ active }">
              <v-checkbox
                color="#7070a0"
                :input-value="active"
                :true-value="item"
              ></v-checkbox>

              <v-list-item-avatar tile size="40" class="mr-4">
                <v-img :src="item.mediaSrc"></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">{{ item.options.quantity }} x {{ item.title }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.currency }} {{ item.unitPrice }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="optionsWithoutQuantity(item)">
                  <span
                    v-for="(value, key, index) in optionsWithoutQuantity(item)"
                    :key="index"
                  >{{ key }}: {{ value }} </span>
                </v-list-item-subtitle>

                <v-list-item-subtitle v-if="products[item.id] && products[item.id].deriveStock(optionsWithoutQuantity(item))">
                  Stock: {{ products[item.id].deriveStock(optionsWithoutQuantity(item)) }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else>
                  <strong>Out of stock</strong>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon @click.stop="confirmRemove(index, item.title)">
                  <v-icon>{{ icon.mdiTrashCanOutline }}</v-icon>
                </v-btn>
              </v-list-item-action>
            </template>
          </v-list-item>
          <v-divider
            v-if="index + 1 < list.items.length"
            :key="'divider' + index"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
    <v-card-actions>
      <v-btn
        text
        @click="$router.go(-1)"
      >Back</v-btn>
      <div class="flex-grow-1"></div>
      <v-btn
        dark
        depressed
        color="#7070a0"
        @click="addToCart"
      >Add to cart</v-btn>
    </v-card-actions>
    <v-dialog
      v-model="confirm.active"
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">Are you sure?</v-card-title>

        <v-card-text>
          Do you really want to remove "<span class="font-weight-bold">{{ confirm.description }}</span>" from your wishlist?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            @click="resetConfirm"
          >
            No
          </v-btn>

          <v-btn
            text
            @click.stop="confirm.onConfirm"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required } from 'vuelidate/lib/validators'
  import { mdiTrashCanOutline } from '@mdi/js'

  export default {
    mixins: [
      validationMixin
    ],
    data () {
      return {
        icon: { mdiTrashCanOutline },
        selected: [],
        confirm: {
          active: false,
          description: "",
          onConfirm: null
        },
        products: []
      }
    },
    activated() {
      this.$store.dispatch('clearError')
    },
    deactivated() {
      this.products = []
      this.selected = []
    },
    validations: {
      selected: { required }
    },
    computed: {
      list() {
        return this.$store.getters.list
      },
      error() {
        return this.$store.getters.error
      }
    },
    watch: {
      list: {
        immediate: true,
        handler: function(val) {
          if (val && val.items && val.items.length) {
            val.items.forEach(item =>
              this.$store.dispatch('getProduct', item.id)
                .then(product =>
                  this.$set(this.products, item.id, product)
                )
            )
          }
        }
      }
    },
    methods: {
      optionsWithoutQuantity(item) {
        const options = { ...item.options }
        delete options['quantity']
        return options
      },
      resetConfirm() {
        this.confirm = {
          active: false,
          description: "",
          onConfirm: null
        }
      },
      confirmRemove(index, description) {
        const self = this

        this.confirm = {
          active: true,
          description: description,
          onConfirm: () => {
            self.$store.dispatch('removeWishlistItem', index)
            self.resetConfirm()
          }
        }
      },
      addToCart() {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          this.$store.dispatch('clearError')
          this.selected.forEach(item => {
            if (this.products[item.id].deriveStock(this.optionsWithoutQuantity(item)) > 0) {
              this.logInfo('Adding to cart', item)
              this.$store.dispatch('removeWishlistItem', item)
                .then(() => this.$store.dispatch('addCartItem', item))
            } else {
              this.$store.dispatch('setError', {
                message: 'Some items are out of stock and cannot be added to your cart!'
              })
            }
          })
          this.$store.dispatch('triggerSnackbar', 'Items added to cart.')
        }
      }
    }
  }
</script>