<template>
  <div v-if="componentLoaded">
    <AccountHeader :account="profileAccount" :product-count="productAggRoots.length"></AccountHeader>
    <v-card>
      <v-progress-linear
        v-if="loading"
        indeterminate
      ></v-progress-linear>

      <v-card-actions class="tools">
        <v-btn-toggle
          dense
          multiple
          class="d-flex"
        >
          <v-btn
            dense
            class="toolbar-button caption"
            @click.stop="downloadProducts"
          >
            <v-icon left>{{ icon.mdiFileDownload }}</v-icon>
            Download
          </v-btn>
          <label
            class="toolbar-button file-upload caption v-btn v-btn--contained theme--light v-size--default"
          >
            <span class="v-btn__content">
              <v-icon left>{{ icon.mdiFileUpload }}</v-icon>
              Upload
              <input
                name="csvFile"
                type="file"
                accept="text/csv"
                @change="changeCSVFile"
              />
            </span>
          </label>
          <label
            class="toolbar-button image-upload caption v-btn v-btn--contained theme--light v-size--default"
          >
            <span class="v-btn__content">
              <v-icon left>{{ icon.mdiFileImage }}</v-icon>
              Image Upload
              <input
                name="imageBulkFile"
                type="file"
                accept=".zip"
                @change="changeBulkImageFile"
              />
            </span>
          </label>
        </v-btn-toggle>
      </v-card-actions>

      <v-card-text
        v-if="!accountProducts.length"
        class="text-center subtitle-1 my-4"
      >
        This account has no products.
      </v-card-text>

      <v-list two-line v-else>
        <Product
          v-for="(product, index) in productAggRoots"
          :product="product"
          :index="index"
          :key="product.id"
          :editButton="user.account == routeAccount"
          deleteButton
          @delete="onDelete"
        />
      </v-list>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          type="button"
          :to="{ name: 'add-product', params: {account: user.account} }"
          color="#7070a0"
          exact
          dark
          depressed
        >
          <v-icon left>{{ icon.mdiPlus }}</v-icon>
          Product
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="confirm.active"
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">Are you sure?</v-card-title>

        <v-card-text>
          Do you really want to delete the product "<span class="font-weight-bold">{{ confirm.description }}</span>"?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="resetConfirm">No</v-btn>
          <v-btn text @click.stop="confirm.onConfirm">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { mdiFileDownload, mdiFileUpload, mdiFileImage, mdiPlus } from '@mdi/js'
  import fb from '@/firebase'
  import ProductAggRoot from '@/aggroot/product'
  import AccountHeader from '@/components/AccountHeader'
  import Product from '@/components/Product'
  import Papa from 'papaparse'
  import FileSaver from 'file-saver'
  import JSZip from 'jszip'
  import { getPathSep } from '@/csfilehandler'

  export default {
    components: {
      AccountHeader,
      Product,
    },
    data: () => ({
      componentLoaded: false,
      icon: {
        mdiFileDownload,
        mdiFileUpload,
        mdiFileImage,
        mdiPlus
      },
      confirm: {
        active: false,
        description: "",
        onConfirm: null
      },
      profileAccount: null,
      productAggRoots: []
    }),
    computed: {
      ...mapGetters([
        'accountProducts',
        'loading'
      ]),
      routeAccount () {
        return this.$route.params.account
      },
      accountProductCodes() {
        return this.accountProducts.map(p => p.code.toLowerCase())
      },
      accountProductsMap() {
        return this.accountProducts.reduce((o, product) =>
          ({ ...o, [product.code.toLowerCase()]: product.id}), {})
      }
    },
    mounted() {
      Promise.all([
        this.bindAccountProductsRef(this.routeAccount),
        this.getAccount(this.routeAccount)
          .then(account => this.profileAccount = account)
      ])
        .then(() => this.componentLoaded = true)
    },
    watch: {
      accountProducts(value) {
        if (value && Array.isArray(value)) {
          //value.map(p => console.log(JSON.stringify(p)))
          this.productAggRoots = value.map(ProductAggRoot.fromObj)
        }
      }
    },
    methods: {
      ...mapActions([
        "bindAccountProductsRef",
        "getAccount"
      ]),
      onDelete(product) {
        this.confirmDelete(product.id, product.title, product.hasMedia)
      },
      resetConfirm() {
        this.confirm = {
          active: false,
          description: "",
          onConfirm: null
        }
      },
      confirmDelete(productId, description, hasMedia) {
        const self = this

        this.confirm = {
          active: true,
          description: description,
          onConfirm: () => {
            self.$store.dispatch('deleteProduct', {account: this.routeAccount, productId, hasMedia})
            self.resetConfirm()
          }
        }
      },
      downloadProducts() {
        const csvData = this.productAggRoots.map(p => p.csv)

        let csv = Papa.unparse(csvData)
        let blob = new Blob([csv], {type: "text/csv;charset=utf-8"})
        let now = new Date()
        let nowString = now.toUTCString().toLowerCase().replace(/[\s,:]|gmt/ig, '')

        FileSaver.saveAs(blob, 'products-' + nowString + '.csv')
      },
      changeCSVFile(e) {
        this.$store.dispatch('setLoading', true)
        const fileData = e.target.files[0]
        Papa.parse(fileData, {
          header: true,
          step: (results) => this.$store.dispatch('createProductFromCSV', {
            results, user: this.user
          }),
          complete: () => this.$store.dispatch('setLoading', false)
        })
      },
      changeBulkImageFile(e) {
        this.$store.dispatch('setLoading', true)
        const fileData = e.target.files[0]
        JSZip.loadAsync(fileData)
          .then(zip => Promise.all(
            Object.values(zip.files)
              .map(file => {
                const filename = file.name.split(getPathSep()).pop()
                const code = filename.split('.')[0]
                if (
                  !file.dir &&
                  !file.name.startsWith('__MACOSX') &&
                  this.accountProductCodes.includes(code.toLowerCase())
                ) {
                  return file.async("blob").then(blob => ({
                    filename,
                    code,
                    product: this.accountProductsMap[code.toLowerCase()],
                    blob
                  }))
                }
              })
              .filter(x => x !== undefined)
          ))
          .then(blobs =>
            Promise.all(
              blobs.map(blob =>
                fb.storage.ref(`products/${ this.user.account }/${ blob.product }`)
                  .put(blob.blob)
                  .then(() => this.$store.dispatch('setProductHasMedia', { productID: blob.product, hasMedia: true }))
              )
            )
          )
          .then(() => this.$store.dispatch('setLoading', false))
          .catch(error => {
            this.$store.dispatch('setLoading', false)
            this.logError('Error uploading zip file.', {error: error.message})
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .tools {
    .v-btn {
      text-transform: none;
    }
    .v-item-group {
      width: 100%;
    }
  }

  .toolbar-button {
    flex: 1 1 auto;
  }

  .file-upload,
  .image-upload {
    cursor: pointer;

    input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
  }
</style>
