<template>
  <StreamPost
    v-if="post"
    :item="post"
  />
</template>

<script>
  import StreamPost from '@/components/StreamPost'

  export default {
    components: {
      StreamPost,
    },

    name: 'SinglePost',

    data() {
      return {
        post: null
      }
    },

    computed: {
      routePost() {
        return this.$route.params.post_id
      }
    },

    activated() {
      this.$store.dispatch('getPost', this.routePost)
        .then(post => {
          this.post = {
            id: this.routePost,
            ...post.data()
          }
        })
    },

    deactivated() {
      this.post = null
    }
  }
</script>

<style lang="scss">
</style>
