<template>
  <v-card>
    <v-toolbar flat dark color="#7070a0">
      <v-toolbar-title>
        <h1 class="title">Order detail</h1>
      </v-toolbar-title>
    </v-toolbar>
    <div v-if="componentLoaded">
      <v-card-title>
        <h2  class="subtitle-1">Ordered {{ formatDate(order.created) }}</h2>
      </v-card-title>
      <v-card-text>
        <ul>
          <li><strong>ID:</strong> {{ order.orderID }}</li>
          <li><strong>Merchant:</strong> {{ order.merchant.legalName }}</li>
          <li><strong>Customer:</strong> {{ order.customer.name }}</li>
        </ul>
        <v-select
          v-if="isPOView"
          label="Order status"
          :loading="loading"
          v-model="orderStatus"
          :items="orderStates"
          class="mt-4"
          hide-details
          @change="updateOrderStatus"
        />
        <table v-if="order.status" class="mt-4 state-history">
          <thead>
            <tr>
              <th>Date/Time</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="state in order.status"
              :key="state.datetime"
            >
              <th>{{ formatDate(state.datetime) }}</th>
              <td>{{ mapState(state.status) }}</td>
            </tr>
          </tbody>
        </table>
      </v-card-text>
      <v-list>
        <template v-for="(item, index) in order.items">
          <v-list-item
            :key="index"
            :value="item"
            class="justify-end"
          >
            <v-list-item-avatar size="40" tile class="item-media mr-2">
              <v-img :src="item.media"></v-img>
            </v-list-item-avatar>

            <v-list-item-content class="item-data">
              <v-list-item-title class="body-2 font-weight-bold">{{ item.quantity }} x {{ item.title }}</v-list-item-title>
              <v-list-item-subtitle v-if="optionsWithoutQuantity(item)" class="caption">
                {{ optionsOutput(item) }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-content class="item-cost caption text-right">
              <div>{{ getPrice(item) }}</div>
            </v-list-item-content>
          </v-list-item>
          <v-divider
            v-if="index + 1 < order.items.length"
            :key="'divider' + index"
          ></v-divider>
        </template>
      </v-list>
      <v-card-text v-if="order.merchant.shippingCost > 0" class="d-flex caption align-start">
        <div>Shipping:</div>
        <div class="flex-grow-1"></div>
        <div class="text-right">{{ getShippingOutput() }}</div>
      </v-card-text>
      <v-card-text class="d-flex title align-start">
        <div>Total:</div>
        <div class="flex-grow-1"></div>
        <div class="text-right">{{ getTotalsOutput() }}</div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="$route.name === 'order-detail'"
          class="back"
          exact
          text
          :to="{name: 'orders'}"
        >
          <v-icon left>{{ icon.mdiArrowLeft }}</v-icon>
          Back to orders
        </v-btn>
        <v-btn
          v-else
          class="back"
          exact
          text
          :to="{name: 'account-orders', params: {account: user.account}}"
        >
          <v-icon left>{{ icon.mdiArrowLeft }}</v-icon>
          Back to purchase orders
        </v-btn>
      </v-card-actions>
    </div>
    <PleaseWait v-else />
  </v-card>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { mdiArrowLeft } from '@mdi/js'
  import { getImageURL } from '@/image-cdn'
  import PleaseWait from '@/components/app/PleaseWait'
  import OrderState from '@/data/orderstate.json'

  export default {

    name: 'OrderDetail',

    components: {
      PleaseWait
    },

    data: () => ({
      icon: { mdiArrowLeft },
      componentLoaded: false,
      orderStates: OrderState,
      orderStatus: 'open',
      order: null
    }),

    mounted() {
      const $app = this
      this.$store
        .dispatch('findOrders', [this.routeOrderID])
        .then(orders => {
          [ this.order ] = orders
          this.orderStatus = (
            this.order &&
            this.order.status &&
            this.order.status[this.order.status.length - 1].status
          ) || 'open'

          this.order.items.forEach((item, index) => {
            $app.$set(
              $app.order.items[index],
              'media',
              getImageURL(
                `/products/${ this.order.merchant.account }/${ item.id }`,
                40, 40,
                item.updated || item.created
              )
            )
          })
        })
        .then(() => this.componentLoaded = true)
    },

    computed: {
      ...mapGetters([
        'loading'
      ]),
      routeOrderID() {
        return this.$route.params.order_id
      },
      routeAccount() {
        return this.$route.params.account
      },
      isPOView() {
        return Boolean(this.routeAccount)
      }
    },

    methods: {
      ...mapActions([
        'updateStatus',
        'setLoading',
        'triggerSnackbar'
      ]),
      mapState: (status) => {
        const state = OrderState.find(s => s.value === status)
        return state && state.text
      },
      formatDate(date) {
        const options = {
          year: 'numeric', month: 'short', day: 'numeric',
          hour: 'numeric', minute: 'numeric',
          hour12: false
        }
        return new Intl
          .DateTimeFormat('default', options)
          .format(new Date(date))
      },
      optionsOutput(item) {
        const options = Object.entries(this.optionsWithoutQuantity(item))

        return options.reduce((acc, currentValue, index, array) => {
          acc += currentValue[1]
          acc += index < array.length - 1 ? ', ' : ''

          return acc
        }, "")
      },
      optionsWithoutQuantity(item) {
        const options = { ...item.options }
        delete options['quantity']
        return options
      },
      getPrice(item) {
        return item.itemTotal && new Intl.NumberFormat(
          navigator.language || 'en', {
            style: 'currency',
            currency: item.currency
          }).format(item.itemTotal)
      },
      getShippingOutput() {
        return new Intl.NumberFormat(
          navigator.language || 'en', {
            style: 'currency',
            currency: this.order.merchant.currency
          }).format(this.order.merchant.shippingCost)
      },
      getTotalsOutput() {
        return new Intl.NumberFormat(
          navigator.language || 'en', {
            style: 'currency',
            currency: this.order.merchant.currency
          }).format(this.order.merchant.merchantOrderTotal)
      },
      updateOrderStatus() {
        this.setLoading(true)
          .then(() => this.updateStatus({
            orderID: this.routeOrderID,
            status: this.orderStatus
          }))
          .then(status => {
            this.order.status = status
            this.setLoading(false)
            this.triggerSnackbar("Order status updated.")
          })
      }
    }
  }
</script>

<style lang="scss">
  .state-history {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    border: 1px solid #eee;

    th, td {
      padding: 0.2rem 0.5rem;
      border-left: 1px solid #eee;
      border-right: 1px solid #eee;
    }

    thead th {
      background: #eee;
      border-bottom: 1px solid #eee;
    }

    tbody th {
      text-align: left;
    }
  }
  .item-media {
    flex: 0 0 40px;
  }
  .item-data {
    flex: 1;
    padding-left: 8px;
  }
  .item-cost {
    flex: 0 0 25%;
  }
</style>
