<template>
  <v-card>
    <v-toolbar class="page-title" flat dark color="#7070a0">
      <v-toolbar-title><h1 class="title">Share</h1></v-toolbar-title>
      <v-toolbar-title v-if="user" class="text-right">{{ user.account }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-list dense>
        <v-subheader>Who would you like to share with?</v-subheader>
        <v-list-item
          v-for="(item, index) in [
            { text: 'With your followers…', route: {name: 'messages-followers', params: {account: user.account}, query: { share }} },
            { text: 'With a merchant you follow…', route: {name: 'messages-new', params: {account: user.account}, query: { share }} }
          ]"
          :key="index"
          :to="item.route"
        >
          <v-list-item-icon>
            <v-icon v-text="icon.mdiMessageText"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>{{ item.text }}</v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mdiMessageText } from '@mdi/js'

  export default {

    name: 'ShareTarget',

    data: () => ({
      icon: {
        mdiMessageText
      }
    }),

    computed: {
      qsTitle() { return this.$route.query.title },
      qsText() { return this.$route.query.text },
      qsUrl() { return this.$route.query.url },
      share() {
        return [
          this.qsTitle, this.qsText, this.qsUrl
        ].filter(x => x).join(" ")
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
