var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.componentLoaded)?_c('v-card',{staticClass:"messages-card"},[_c('v-toolbar',{staticClass:"page-title",attrs:{"flat":"","dark":"","color":"#7070a0"}},[_c('v-toolbar-title',[_c('h1',{staticClass:"title"},[_vm._v("Messages")])]),(_vm.user)?_c('v-toolbar-title',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.user.account))]):_vm._e()],1),(!_vm.hasMessages)?_c('v-card-text',{staticClass:"messages-card--text text-center subtitle-1 my-4"},[_vm._v(" You currently have no messages. ")]):_c('v-list',[_vm._l((_vm.displayMessages),function(message,index){return [_c('v-list-item',{key:index,class:{'is-notice': message.isNotice, 'is-read': message.read, 'display-message': true},attrs:{"to":message.isNotice ?
          {name: 'message-detail', params: {account: _vm.user.account, message_id: message.id}} :
          {name: 'messages-convo', params: {account: _vm.user.account, recipient: _vm.getParticipantKey(message, 'account')}}}},[_c('v-list-item-content',{staticClass:"display-sender"},[_c('v-list-item-title',{staticClass:"body-2"},[_vm._v(" "+_vm._s(_vm.getParticipantKey(message, 'account'))+" ")])],1),_c('v-list-item-content',{staticClass:"display-text"},[_c('v-list-item-subtitle',{staticClass:"body-2"},[(message.isNotice)?_c('strong',[_vm._v("Notice:")]):_vm._e(),_vm._v(" "+_vm._s(message.text)+" ")])],1),_c('v-list-item-content',{staticClass:"display-date"},[_c('v-list-item-subtitle',{staticClass:"caption"},[_vm._v(_vm._s(_vm.formatDate(message.sent)))])],1)],1),(index < _vm.displayMessages.length - 1)?_c('v-divider',{key:("divider" + index)}):_vm._e()]})],2),_c('v-card-actions',{staticClass:"justify-end"},[(_vm.isSeller)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var value = ref.value;
return [_c('v-btn',_vm._g({attrs:{"color":"#7070a0","dark":"","depressed":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.icon.mdiMessagePlus))]),_vm._v(" New message "),(value)?_c('v-icon',{attrs:{"right":""}},[_vm._v(_vm._s(_vm.icon.mdiMenuUp))]):_c('v-icon',{attrs:{"right":""}},[_vm._v(_vm._s(_vm.icon.mdiMenuDown))])],1)]}}],null,false,2684269211)},[_c('v-list',_vm._l(([
            { text: 'Direct', route: {name: 'messages-new', params: {account: _vm.user.account}} },
            { text: 'To followers', route: {name: 'messages-followers', params: {account: _vm.user.account}} }
          ]),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":item.route}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)}),1)],1):_c('v-btn',{attrs:{"color":"#7070a0","dark":"","depressed":"","to":{name: 'messages-new', params: {account: _vm.user.account}}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.icon.mdiMessagePlus))]),_vm._v(" New message ")],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }