<template>
  <div v-if="componentLoaded">
    <v-card>
      <v-card-title>
        <h1 class="headline">Manage products/services</h1>
      </v-card-title>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-chip
          class="ma-2"
          color="green"
          text-color="white"
        >{{ products.length }} product(s) in post</v-chip>
        <div class="flex-grow-1"></div>
      </v-card-actions>
      <v-card-text
        v-if="!products.length"
        class="text-center subtitle-1 my-4"
      >
        You haven't added any products or services to this post yet.
      </v-card-text>
      <v-list v-else>
        <Product
          v-for="(product, index) in products"
          :product="product"
          :post="postId"
          :key="index"
          editButton
          deleteButton
          @delete="onDelete"
        />
      </v-list>
      <v-card-actions v-if="products.length < MAX_PRODUCTS">
        <v-btn
          v-if="hasProducts"
          type="button"
          color="#7070a0"
          outlined
          :to="{ name: 'post-select-product', params: {account: user.account, postId: postId} }"
        >
          Select existing
        </v-btn>
        <div class="flex-grow-1"></div>
        <v-btn
          type="button"
          color="#7070a0"
          outlined
          :to="{ name: 'post-add-product', params: {account: user.account, postId: postId} }"
        >
          Create new
        </v-btn>
      </v-card-actions>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
          color="#7070a0"
          dark
          depressed
          :to="{name: 'stream'}"
        >Complete</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="confirm.active"
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">Are you sure?</v-card-title>

        <v-card-text>
          Do you really want to remove the product or service
          "<span class="font-weight-bold">{{ confirm.description }}</span>"
          from this post?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="resetConfirm">No</v-btn>
          <v-btn text @click.stop="confirm.onConfirm">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import Product from '@/components/Product'

  export default {
    components: {
      Product,
    },
    data() {
      return {
        MAX_PRODUCTS: 3,
        componentLoaded: false,
        confirm: {
          active: false,
          description: "",
          onConfirm: null
        },
        hasProducts: false,
        postProducts: [],
        products: []
      }
    },
    activated() {
      this.hasAccountProducts(this.user.account)
        .then(hasProducts => this.hasProducts = hasProducts)
      this.loadPostProducts()
        .then(this.loadProducts)
        .then(() => this.componentLoaded = true)
    },
    deactivated() {
      this.hasProducts = false
      this.postProducts = []
      this.products = []
    },
    computed: {
      postId () {
        return this.$route.params.post_id
      }
    },
    methods: {
      ...mapActions([
        'hasAccountProducts',
        'getPost',
        'getPostProducts',
        'deletePostProduct'
      ]),
      loadPostProducts() {
        return this.getPost(this.postId)
          .then(postSnapshot =>
            this.postProducts = (postSnapshot.get('products') || [])
              .filter(Boolean)
          )
      },
      loadProducts() {
        this.products = []
        return this.getPostProducts(this.postProducts)
          .then(querySnapshot => querySnapshot ?
            querySnapshot.forEach(productSnapshot =>
              this.products.push({
                ...productSnapshot.data(),
                id: productSnapshot.id
              })
            ) : undefined
          )
      },
      onDelete(product) {
        this.confirmDelete(product.id, product.description)
      },
      resetConfirm() {
        this.confirm = {
          active: false,
          description: "",
          onConfirm: null
        }
      },
      confirmDelete(productId, description) {
        const self = this

        this.confirm = {
          active: true,
          description: description,
          onConfirm: () => {
            self.$store.dispatch('setLoading', true)
            self.deletePostProduct({
              postID: this.postId,
              productID: productId
            })
              .then(() => {
                const idx = this.products.findIndex(p => p.id === productId)
                if (idx > -1) {
                  this.products.splice(idx, 1)
                }
                self.$store.dispatch('setLoading', false)
              })
            self.resetConfirm()
          }
        }
      }
    }
  }
</script>