<template>
  <v-card>
    <v-toolbar class="page-title" flat dark color="#7070a0">
      <v-toolbar-title><h1 class="title">Orders</h1></v-toolbar-title>
    </v-toolbar>
    <div v-if="componentLoaded">
      <v-card-text
        v-if="!orders || !orders.length"
        class="text-center subtitle-1 my-4"
      >
        You do not currently have any orders.
      </v-card-text>

      <v-list v-else>
        <OrderSummary
          v-for="(order, index) in orders"
          :order="order"
          :key="index"
        ></OrderSummary>
      </v-list>
    </div>
    <PleaseWait v-else />
  </v-card>
</template>

<script>
  import OrderSummary from '@/components/OrderSummary'
  import PleaseWait from '@/components/app/PleaseWait'

  export default {

    name: 'Orders',

    components: {
      OrderSummary,
      PleaseWait
    },

    data() {
      return {
        componentLoaded: false,
        orders: []
      }
    },

    watch: {
      user: {
        immediate: true,
        handler(value) {
          if (value && value.email) {
            this.loadOrders()
              .then(() => this.componentLoaded = true)
          }
        }
      }
    },

    deactivated() {
      this.componentLoaded = false
      this.orders = []
    },

    methods: {
      loadOrders() {
        return this.$store
          .dispatch('getCustomerOrders', this.user.email)
          .then(querySnapshot =>
            this.orders = querySnapshot ? querySnapshot.docs.map(doc => doc.data()) : []
          )
      }
    }
  }
</script>

<style lang="scss">
</style>
