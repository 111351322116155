<template>
  <form method="post" @submit.prevent="submit">
    <v-card>
      <v-card-title>
        <h1 class="headline">Product Variants</h1>
      </v-card-title>
      <div v-if="componentLoaded">
        <v-card-text class="pt-0">
          <v-list two-line>
            <ProductComponent
              :product="product"
              :key="product.id"
              :prices="true"
            />
          </v-list>
          <h2 class="headline mb-2">Existing variants</h2>
          <div
            v-if="!product.variants || !product.variants.length"
            class="text-center body-2 my-8"
          >
            This product has no variants.
          </div>

          <v-card-text
            v-else
            v-for="(variant, varidx) in product.variants"
            :key="'variant' + varidx"
            class="variant"
          >
            <h3 class="h6">
              <span
                v-for="(option, optidx) in product.customOptions" :key="'variantoption' + varidx + optidx">
                {{ option.description }}:
                {{ variant[option.description.toLowerCase()] }}<span v-if="optidx + 1 < product.customOptions.length">,</span>
              </span>
            </h3>
            <v-row>
              <v-col>
                <div v-if="variant.code">{{ variant.code }}</div>
                <div>Stock: {{ variant.stock }}</div>
              </v-col>
              <v-col v-if="variant.prices" class="text-right">
                <div
                  v-for="price in variant.prices"
                  :key="'price' + varidx + price.currency"
                >
                  {{ price.currency }} {{ parseFloat(price.unitPrice).toFixed(2) }}
                </div>
              </v-col>
            </v-row>
            <div class="text-right">
              <v-btn icon @click.stop="selectVariant(variant)">
                <v-icon>{{ icon.mdiFileDocumentEditOutline }}</v-icon>
              </v-btn>

              <v-btn icon @click.stop="confirmRemoveVariant(variant)">
                <v-icon>{{ icon.mdiTrashCanOutline }}</v-icon>
              </v-btn>
            </div>
            <v-divider
              v-if="varidx + 1 < product.variants.length"
              class="mt-2"
            ></v-divider>
          </v-card-text>

          <h2 class="headline mb-2" id="edit-variant">Edit variant</h2>
          <v-select
            v-for="(select, index) in product.customOptions"
            :key="index"
            :label="select.description"
            :items="select.options"
            v-model.trim="$v.variant[select.description.toLowerCase()].$model"
            @input="bindSelect(select)"
            @blur="bindSelect(select)"
          ></v-select>
          <div v-if="optionsSelected">
            <v-text-field
              label="Product code / SKU"
              v-model.trim="variant.code"
            ></v-text-field>
            <v-text-field
              label="Current stock"
              v-model.trim="$v.variant.stock.$model"
              @input="$v.variant.stock.$touch()"
              @blur="$v.variant.stock.$touch()"
              :error-messages="stockErrors()"
            ></v-text-field>
            <PriceForm
              v-model="variant.prices"
              title="Variant price"
              :minimum="0"
              :include-shipping="this.account.shipping === 'product' && product.shipping === 'available'"
            />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :to="backRoute"
            class="back"
            text exact>
            <v-icon left>{{ icon.mdiArrowLeft }}</v-icon>
            Back to products
          </v-btn>
          <div class="flex-grow-1"></div>
          <v-btn
            :loading="loading"
            dark
            depressed
            color="#7070a0"
            type="submit"
          >Save</v-btn>
        </v-card-actions>
      </div>
      <PleaseWait v-else/>
    </v-card>
    <ConfirmDialog
      v-model="remove.active"
      @confirm="remove.onConfirm()"
    >Are you sure you wish to delete the variant "{{ remove.description }}"?</ConfirmDialog>
  </form>
</template>

<script>
  import { mapGetters } from 'vuex'
  import fb from '@/firebase'
  import { validationMixin } from 'vuelidate'
  import { required, decimal } from 'vuelidate/lib/validators'
  import ProductComponent from '@/components/Product'
  import PriceForm from '@/components/PriceForm'
  import PleaseWait from '@/components/app/PleaseWait'
  import ConfirmDialog from '@/components/app/ConfirmDialog'
  import {
    mdiArrowLeft,
    mdiHelpCircle,
    mdiMinus,
    mdiPlus,
    mdiFileDocumentEditOutline,
    mdiTrashCanOutline
  } from '@mdi/js'

  export default {

    name: 'ProductVariants',

    mixins: [validationMixin],

    components: {
      ProductComponent,
      PriceForm,
      PleaseWait,
      ConfirmDialog
    },

    data: () => ({
      componentLoaded: false,
      icon: {
        mdiArrowLeft,
        mdiHelpCircle,
        mdiMinus,
        mdiPlus,
        mdiFileDocumentEditOutline,
        mdiTrashCanOutline
      },
      product: {},
      variant: {
        stock: 1,
        code: "",
        prices: []
      },
      variantReset: {
        stock: 1,
        code: "",
        prices: []
      },
      remove: {
        active: false,
        onConfirm: () => {}
      }
    }),

    validations() {
      let v = {
        variant: {
          stock: {
            required,
            decimal
          }
        }
      }

      this.product.customOptions.forEach(custom => {
        v.variant[custom.description.toLowerCase()] = { required }
      })

      return v
    },

    computed: {
      ...mapGetters([
        'account'
      ]),
      error() {
        return this.$store.getters.error
      },
      loading() {
        return this.$store.getters.loading
      },
      routeProduct() {
        return this.$route.params.product_id
      },
      variantRoute() {
        return {
          name: 'edit-product-variants',
          params: { account: this.product.account, product_id: this.product.id }
        }
      },
      backRoute() {
        return {
          name: 'account-products-manage',
          params: {
            account: this.user.account
          }
        }
      },
      optionsSelected() {
        return this.product &&
          this.product.customOptions &&
          this.product.customOptions.every(
            o => this.variant[o.description.toLowerCase()]
          )
      }
    },

    activated() {
      if (this.routeProduct) {
        this.$store.dispatch('getProduct', this.routeProduct)
          .then(product => {
            this.product = product

            this.product.customOptions.forEach(custom =>
              this.$set(this.variant, custom.description.toLowerCase(), "")
            )

            this.componentLoaded = true
          }).catch(error => {
            this.logError(error.message)
          })
      } else {
        this.componentLoaded = true
      }
    },

    methods: {
      confirmRemoveVariant(variant) {
        const self = this
        this.remove = {
          active: true,
          description: this.product.customOptions.map(
            opt => `${opt.description}: ${variant[opt.description.toLowerCase()]}`
          ).join(", "),
          onConfirm: () => {
            self.removeVariant(variant)
            self.remove = {
              active: false,
              onConfirm: () => {}
            }
          }
        }
      },
      removeVariant(variant) {
        this.$store.dispatch('setLoading', true)
        this.product.deleteVariant(variant)
        fb.db.collection('products')
          .doc(this.product.id)
          .set(this.product.toObj())
          .then(() => {
              this.logInfo("Product stored.", {product: this.product.id})
              this.$store.dispatch('setLoading', false)
            })
          .catch((error) => {
            this.$store.dispatch('setLoading', false)
            this.logError("Error updating product: ", {error: error.message})
          })
      },
      bindSelect(select) {
        this.$v.variant[select.description.toLowerCase()].$touch()
        this.loadVariant()
      },
      selectVariant(variant) {
        this.product.customOptions.forEach(opt => {
          const option = opt.description.toLowerCase()
          this.variant[option] = variant[option]
        })
        this.loadVariant()
      },
      loadVariant() {
        if (this.optionsSelected) {
          const search = this.product.customOptions.reduce((acc, opt) => {
            const label = opt.description.toLowerCase()
            acc[label] = this.variant[label]
            return acc
          }, {})

          const found = this.product.getVariant(search)

          if (found) {
            this.variant = { ...found }
            document.getElementById("edit-variant").scrollIntoView()
          } else {
            this.variant = {
              ...search,
              ...this.variantReset
            }
          }
        }
      },
      stockErrors() {
        const errors = []
        const field = this.$v.variant.stock
        if (!field.$dirty) return errors
        !field.required && errors.push("Stock is required")
        !field.decimal && errors.push("Stock must be a decimal value")
        return errors
      },
      submit() {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          this.$store.dispatch('setLoading', true)
          this.logInfo("Updating", {product: this.product.id})

          fb.db.collection('products')
            .doc(this.product.id)
            .set(this.product.updateVariant(this.variant).toObj())
            .then(() => {
                this.$v.$reset()
                this.variant = { ...this.variantReset }
                this.product.customOptions.forEach(custom =>
                  this.$set(this.variant, custom.description.toLowerCase(), "")
                )
                this.logInfo("Product stored.", {product: this.product.id})
                this.$store.dispatch('setLoading', false)
              })
            .catch((error) => {
              this.$store.dispatch('setLoading', false)
              this.logError("Error updating product: ", {error: error.message})
            })
        }
      }
    }
  }
</script>
